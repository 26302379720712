<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
             <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
           <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />

          <p class="v3-sub-title">One app - all your connections</p>
          <h2>One more step</h2>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="8" lg="7">
              <div>
                <p>Setup your password</p>

                <v-row justify="center">
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      placeholder="Enter Your Password"
                      @click:append="show1 = !show1"
                      :error-messages="errorPassword"
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                      outlined
                      class="h50-field"
                      @keyup.enter="reset_password"
                    ></v-text-field>
                  </v-col>
                  <br />
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      v-model="confirm_password"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      placeholder="Re-type Password"
                      @click:append="show2 = !show2"
                      :error-messages="confirmPasswordErrors"
                      @input="$v.confirm_password.$touch()"
                      @blur="$v.confirm_password.$touch()"
                      outlined
                      class="h50-field"
                      @keyup.enter="reset_password"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <div class="d-flex text-start">
                  <v-checkbox dense v-model="checkbox"></v-checkbox>

                  <p>
                    I have read and accept the
                    <span class="v3-terms"
                      ><a href="/terms-and-conditions" target="_blank"
                        >terms & conditions</a
                      >
                    </span>
                    <span> as well as the</span>

                    <span class="v3-terms">
                      <a href="/privacy-policy" target="_blank"
                        >privacy policy.</a
                      >
                    </span>
                  </p>
                </div>
                <div>
                  <v-btn
                    :loading="saveLoading"
                    class="green-bg-line-btn mt-3"
                    outlined
                    block
                    depressed
                    large
                    @click="completeRegistration('password')"
                  >
                    Complete Registration
                  </v-btn>
                </div>

                <br />
              </div>

              <br />
              <br />
              <div class="merchant-step-area">
                <p>4/4</p>
                <img
                  :src="'/assets/v3_assets/custom_shapes/m_step_4.png'"
                  alt="Logo"
                />
              </div>
              <!-- <div @click="goBack()" class="auth-back-btn i">
                <v-btn class="v3-div-clr" elevation="0" depressed flex>
                  <img
                    class="v3-back-btn"
                    :src="'/assets/v3_assets/back_btn.svg'"
                  />
                  Back
                </v-btn>
              </div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import MerchantBannerWidget from "../auth/MerchantBannerWidget.vue";
import axios from "axios";
import Cookies from "js-cookie";
export default {
  props: ["userInfo"],
  components: {
    MerchantBannerWidget,
  },
  data() {
    return {
      saveLoading: false,
      e1: 1,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      show1: false,
      show2: false,
      password: "",
      confirm_password: "",
      checkbox: false,
      terms_conditions: false,
      snackbar: {
        show: false,
        message: "",
        color: null,
      },
    };
  },
  validations: {
    password: { required, minLength: minLength(8) },
    confirm_password: {
      required,
      minLength: minLength(8),
      sameAs: sameAs("password"),
    },
  },
  created() {
    if (this.userInfo) {
    } else {
      // this.$router.replace("/v3/merchant/signup");
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "merchantRegisterYourCompany",
        params: { userInfo: this.userInfo },
      });
    },
    async completeRegistration(value) {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.validate = false;
        this.snackbar = {
          message: "Fill in all the fields",
          color: "red",
          show: true,
        };
      } else {
        const form = new FormData();
        form.append("password", this.password);
        form.append("section", value);
        form.append("merchant_id", this.userInfo.id);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("merchantRegisterSetupPassword");
        form.append("GCtoken", GCtoken);
        this.saveLoading = true;
        axios
          .post("/api/v3/merchant_register", form)
          .then((response) => {
            this.saveLoading = false;
            this.$emit("login", response.data.data);
            this.loader = false;
            let token = "Bearer " + response.data.data.token;
            axios.defaults.headers.common["Authorization"] = token;
            var remember = "";
            var now = new Date();
            now.setTime(now.getTime() + 1 * 3600 * 1000);
            Cookies.set("access_token", token, { expires: remember ? now : 1 });
            this.$store.commit("MERCHANT_LOGIN_STATUS", true);
            this.$router.push({ name: "merchantConfirmationPage" });
            return;
            // this.$router.push({ name: "merchantDashboard" });
          })
          .catch((e) => {
            this.saveLoading = false;
              this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
             this.$router.replace("/v3/merchant/signup");
          });
      }
    },
  },
  computed: {
    ...mapState({}),
    errorPassword() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at minimum 8 characters ");
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirm_password.$dirty) return errors;
      !this.$v.confirm_password.required &&
        errors.push("Retype Password is required.");
      !this.$v.password.minLength &&
        errors.push("Confirm Password must be at least 8 Character");
      !this.$v.confirm_password.sameAs && errors.push("Password not Matched.");
      return errors;
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
