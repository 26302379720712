<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
             <br />
          <MerchantBannerWidget />
        </v-col>
        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />
          <p class="v3-sub-title">One app - all your connections</p>

          <h2 class="v3-title-text mt-2">
            Congrats! you 're <br />
            all signed up!
          </h2>

          <p class="v3-sub-p">
            Keep a look out for an email from our Dustid<br />
            team confirming your registration.
          </p>
          <p class="v3-sub-p">
            This will provide you with the next steps to login to your<br />
            Dustid dashboard and join our growing community.
          </p>

          <div class="congrats-actions">
            <v-row class="zero-flex" justify="center">
              <v-col cols="12" sm="12" md="6" lg="6">
                <h3>In the meantime</h3>
                <br />
                <v-btn
                  class="primary-bg-line-btn"
                  block
                  depressed
                  outlined
                  large
                  @click="goToDashboard()"
                >
                  Trial our demo dashboard
                </v-btn>
                <br />

                <v-btn
                  class="primary-bg-line-btn"
                  block
                  depressed
                  outlined
                  large
                >
                  Explore our FAQ's
                </v-btn>
                <br />
                <h3>Follow us</h3>
                <br />

                <v-row class="zero-flex" justify="center">
                  <v-col cols="4" sm="4" md="2" lg="2">
                    <v-icon color="primary"> mdi-facebook </v-icon>
                  </v-col>
                  <v-col cols="4" sm="4" md="2" lg="2">
                    <v-icon color="primary">mdi-instagram</v-icon>
                  </v-col>
                  <v-col cols="4" sm="4" md="2" lg="2">
                    <v-icon color="primary">mdi-linkedin</v-icon>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <div @click="goBack()" class="auth-back-btn i">
                <v-btn class="v3-div-clr" elevation="0" depressed flex>
                  <img
                    class="v3-back-btn"
                    :src="'/assets/v3_assets/back_btn.svg'"
                  />
                  Back
                </v-btn>
              </div> -->
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import MerchantBannerWidget from "./MerchantBannerWidget.vue";

export default {
  components: {
    MerchantBannerWidget,
  },
  methods: {
    goBack() {
      // this.$router.push({
      //   name: "merchantRegisterSetupPassword",
      //   params: { userInfo: this.userInfo },
      // });
    },
    goToDashboard() {
      this.$router.push({ name: "merchantDashboard" });
    },
  },

  computed: {
    ...mapState({}),
  },
};
</script>
