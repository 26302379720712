<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
             <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />

          <p class="v3-sub-title">One app - all your connections</p>
          <h2 class="mt-5">Getting to know you</h2>

          <v-row class="zero-flex" justify="center">
            <v-col class="py-0" cols="12" sm="12" md="8" lg="7">
              <div>
                <p>Your Details</p>
                <div class="d-flex mb-3"></div>
                <v-row justify="center">
                  <v-col class="py-0" cols="12" sm="6" md="6">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="First Name"
                      outlined
                      v-model="merchant.firstname"
                      :error-messages="firstNameErrors"
                      @input="$v.merchant.firstname.$touch()"
                      @blur="$v.merchant.firstname.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                  <br />
                  <v-col class="py-0" cols="12" sm="6" md="6">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Last Name"
                      outlined
                      v-model="merchant.lastname"
                      :error-messages="lastNameErrors"
                      @input="$v.merchant.lastname.$touch()"
                      @blur="$v.merchant.lastname.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col class="py-0" cols="5">
                    <v-select
                      background-color="white"
                      :items="country_codes"
                      v-model="merchant.country_code"
                      class="h50-field"
                      placeholder="Uk"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" cols="7">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Phone Number"
                      v-model="merchant.phone_number"
                      :error-messages="phoneErrors"
                      @input="$v.merchant.phone_number.$touch()"
                      @blur="$v.merchant.phone_number.$touch()"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-col
                  class="d-flex justify-center align-center pt-0"
                  cols="12"
                  md="4"
                  v-if="email_otp_send"
                >
                  <v-btn color="primary" class="mt-2" @click="confirmEmail"
                    >confirm</v-btn
                  >
                </v-col>
                <v-col class="py-0 mt-0 mb-5 text-center" cols="12" md="12">
                  <span v-show="!email_resendFlag && email_otp_send"
                    >Resend SMS in :
                  </span>
                  <span
                    id="email_otpTimer"
                    v-show="email_otp_send"
                    class="mr-2"
                  ></span>
                  <span
                    class="mt-2"
                    v-show="email_resendFlag && email_otp_send"
                  >
                    Not Received ?
                    <v-btn color="primary" small text @click="onEmailSubmit()"
                      >Resend OTP</v-btn
                    >
                  </span>
                </v-col>
                <v-row justify="center">
                  <v-col class="py-0" cols="12">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Job Title"
                      outlined
                      v-model="merchant.job_title"
                      :error-messages="jobTitleErrors"
                      @input="$v.merchant.job_title.$touch()"
                      @blur="$v.merchant.job_title.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col class="py-2" cols="12">
                    <v-btn
                      :loading="loading"
                      class="primary-bg-line-btn"
                      large
                      outlined
                      block
                      @click="yourDetails('user_info')"
                    >
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <br />
              <br />
              <div class="merchant-step-area">
                <p>1/4</p>
                <img
                  :src="'/assets/v3_assets/custom_shapes/m_step_1.png'"
                  alt="Logo"
                />
              </div>
               <div @click="goBack()" class="auth-back-btn i">
            <!-- <v-btn class="v3-div-clr" elevation="0" depressed flex>
              <img
                class="v3-back-btn"
                :src="'/assets/v3_assets/back_btn.svg'"
              />
                Back 
            </v-btn> -->
          </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import MerchantBannerWidget from "../auth/MerchantBannerWidget.vue";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import axios from "axios";
export default {
  props: ["userInfo"],
  components: { MerchantBannerWidget },
  computed: {
    ...mapState({}),
    firstNameErrors() {
      const errors = [];
      if (!this.$v.merchant.firstname.$dirty) return errors;
      !this.$v.merchant.firstname.required &&
        errors.push("First Name is required.");
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.merchant.lastname.$dirty) return errors;
      !this.$v.merchant.lastname.required &&
        errors.push("Last Name is required.");
      return errors;
    },

    jobTitleErrors() {
      const errors = [];
      if (!this.$v.merchant.job_title.$dirty) return errors;
      !this.$v.merchant.job_title.required &&
        errors.push("Job Title is required.");
      return errors;
    },
    phoneErrors(custom_error) {
      const errors = [];
      if (!this.$v.merchant.phone_number.$dirty) return errors;
      !this.$v.merchant.phone_number.required &&
        errors.push("Phone Number is required.");
      !this.$v.merchant.phone_number.minLength &&
        errors.push("Phone Number must be at least 10 digits.");
      return errors;
    },
  },
  created() {
    if (this.userInfo) {
      var item = this.userInfo;
      this.merchant.email_id = item.email_id;
      this.merchant.firstname = item.firstname;
      this.merchant.lastname = item.lastname;
      this.merchant.phone_number = item.phone_number;
      this.merchant.job_title = item.job_title;
    } else {
      this.$router.replace("/v3/merchant/signIn");
    }
  },
  data: () => ({
    loading: false,
    //Mobile verification keys
    verify_otp: "",
    mobile_no_verify: false,
    recaptchaFlag: false,
    country_codes: ["+91", "+44"],
    otp_send: false,
    confirmStatus: false,
    // Email Verification
    email_otp_send: false,
    email_confirmStatus: false,
    email_verify_otp: "",
    email_verify: false,
    email_code_confirm_dialog: false,
    email_resendFlag: false,
    email_timeLeft: 30,
    email_timerId: null,
    signup_from_login_dialog: false,
    email_resendFlag: false,
    verify_otp: "",
    phone_number_verify: false,
    code_confirm_dialog: false,
    resendFlag: false,
    firebase_error: false,
    timeLeft: 30,
    timerId: null,
    validate: false,
    snackbar: {
      show: false,
      message: "",
      color: null,
    },
    merchant: {
      email_id: "",
      firstname: "",
      lastname: "",
      country_code: "+44",
      phone_number: "",
      job_title: "",
    },
    termsDialog: false,
  }),
  validations: {
    merchant: {
      firstname: { required },
      lastname: { required },
      job_title: { required },
      email: "",
      phone_number: {
        required,
        minLength: minLength(10),
      },
    },
  },
  methods: {
    goBack() {
      this.$router.replace("/v3/merchant/signup");
    },
    async yourDetails(value) {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.validate = false;
        this.snackbar = {
          message: "Fill in all the fields",
          color: "red",
          show: true,
        };
      } else {
        const form = new FormData();
        form.append("email_id", this.merchant.email_id);
        form.append("firstname", this.merchant.firstname);
        form.append("lastname", this.merchant.lastname);
        form.append("job_title", this.merchant.job_title);
        form.append("country_code", this.merchant.country_code);
        form.append("phone_number", this.merchant.phone_number);
        form.append("section", value);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("merchantRegisterYourDetail");
        form.append("GCtoken", GCtoken);
        this.loading = true;
        axios
          .post("/api/v3/merchant_register", form)
          .then((response) => {
            this.loading = false;
            this.$router.push({
              name: "merchantRegisterYourAddress",
              params: { userInfo: response.data.data },
            });
          })
          .catch((e) => {
            this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
            this.loading = false;
          });
      }
    },
    //***************************************Phone Otp Related Method********************************************************************************** */
    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },
    onSubmit() {
      this.timeLeft = 30;
      this.resendFlag = false;
      if (!this.merchant.phone_number) {
        this.errorSnackBar("Please enter your Valid mobile number");
        return false;
      }
      // Send otp with server side api
      if (
        this.$constants.AUTHENTICATION_OTP_FROM == this.$constants.SERVERSIDE
      ) {
        var obj = {
          phone: this.merchant.phone_number,
          country_code: this.merchant.country_code,
          verify_merchant: true,
        };
        axios
          .post("/api/send_signup_otp_api", obj)
          .then((response) => {
            if (response.data.status == 200) {
              this.timerId = setInterval(this.countdown, 1000);
              this.otp_send = true;

              this.snackbar.show = true;
              this.snackbar.color = "primary";
              this.snackbar.message = "OTP send your mobile number";
            } else {
              this.otp_send = false;
              this.errorSnackBar(response.data.message);
            }
          })
          .catch((e) => {
            this.otp_send = false;
            this.errorSnackBar(e.response.data.message);
          });
        return;
      }

      var phoneNumber = this.merchant.country_code + this.merchant.phone_number;
      var appVerifier = window.recaptchaVerifier;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          this.timerId = setInterval(this.countdown, 1000);
          this.otp_send = true;
          this.firebase_error = false;
          this.snackbar.show = true;
          this.snackbar.color = "primary";
          this.snackbar.message = "OTP sent on your mobile number";
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          this.otp_send = false;
          this.firebase_error = true;

          var error_message =
            "We have blocked all requests from this device due to unusual activity. Try again later";
          this.$printLog(error);
          if (error.code != "auth/captcha-check-failed") {
            var msg = "Your mobile number is invalid";
            this.errorSnackBar(msg);
          }
          if (error.message) {
            if (error.code == "auth/too-many-requests") {
              msg = error_message;
              this.errorSnackBar(msg);
            } else {
              this.errorSnackBar(error.message);
            }
          }
        });
    },
    confirm() {
      if (!this.verify_otp) {
        this.snackbar.show = true;
        this.snackbar.color = "error";
        this.snackbar.message = "Please enter OTP";
        return false;
      }

      // Verify otp with server side api
      if (
        this.$constants.AUTHENTICATION_OTP_FROM == this.$constants.SERVERSIDE
      ) {
        var obj = {
          phone: this.merchant.phone_number,
          country_code: this.merchant.country_code,
          otp: this.verify_otp,
        };
        axios
          .post("/api/verify_Otp_api", obj)
          .then((response) => {
            if (response.data.status == 200) {
              this.phone_number_verify = true;
              this.confirmStatus = true;
              this.resendFlag = false;
              this.otp_send = false;
              this.snackbar.show = true;
              this.snackbar.color = "primary";
              this.snackbar.message = "Number Verified Successfully";
              this.emitToParent();
            } else {
              this.phone_number_verify = false;
              this.errorSnackBar(response.data.message);
            }
          })
          .catch((e) => {
            this.phone_number_verify = false;
            this.errorSnackBar(e.response.data.message);
          });

        return;
      }

      // firebase
      window.confirmationResult
        .confirm(this.verify_otp)
        .then((user) => {
          this.phone_number_verify = true;
          this.resendFlag = false;
          this.confirmStatus = true;
          this.otp_send = false;
          this.firebase_error = false;

          this.snackbar.show = true;
          this.snackbar.color = "primary";
          //TODO: Create a constants file for message strings
          this.snackbar.message = "Number Verified Successfully";
        })
        .catch((e) => {
          this.phone_number_verify = false;
          this.resendFlag = false;
          this.errorSnackBar("OTP is invalid try again.");
        });
    },
    countdown() {
      var elem = document.getElementById("otpTimer");
      if (this.timeLeft == -1) {
        clearTimeout(this.timerId);
        elem.innerHTML = "";
        this.resendFlag = true;
      } else {
        elem.innerHTML = this.timeLeft + "s";
        this.timeLeft--;
      }
    },
    changeNumber() {
      this.otp_send = false;
      this.firebase_error = false;
      this.timeLeft = -1;
    },

    // ************************************Email***************************************************************************
  },
};
</script>

<style scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
