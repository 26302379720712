import axios from "axios";
import { reject } from "lodash";

const state = {
  websitesList: [],
  loaderstate: false,
  showSnackBar: {
    show: false,
    message: "",
    color: "",
  },
};

const getters = {};

const actions = {
  delete_marchant_websites(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/delete_marchant_websites";
    axios
      .post(api_url, data)
      .then((response) => {
        context.commit("SET_WEBSITES", response.data.data);
        context.commit("SHOW_LOADER", false);
        context.commit("SHOW_SNACKBAR", {
          success: true,
          message: "Delete succesfully",
        });
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: "Delete Failed!",
        });
      });
  },
  create_update_website(context, data) {
    return new Promise((resolve, reject) => {
      context.commit("SHOW_LOADER", true);
      var api_url = "/api/merchant/create_update_website";
    console.log(data);
      var form = new FormData();
      form.append('website', data.website);
      form.append('id', data.id);
      form.append('merchant_id', data.user_id);
      form.append('website_name', data.website_name);
      form.append('category_id', data.category_id);
      form.append('website_description', data.websiteDisc);
      if (data.file) {
        form.append('file', data.file);
      }
      if (data.back_image_file) {
        form.append('back_image_file', data.back_image_file);
      }
      axios
        .post(api_url, form)
        .then((response) => {
          resolve(response);
          if (response.data.status == '200') {
            context.commit("SET_WEBSITES", response.data.data);

          }
          context.commit("SHOW_LOADER", false);
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: response.data.message,
          });
        })
        .catch((error) => {
          reject(error);
          context.commit("SHOW_LOADER", false);
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: response.data.message,
          });
        });

    })
  },

  get_marchant_websites(context, data) {
    context.commit("SHOW_LOADER", true);
    var form = new FormData();
    if (data) {
      if (data.merchant_id) {
        form.append('merchant_id', data.merchant_id);
      }
    }
    var api_url = "/api/merchant/get_marchant_websites";
    axios
      .post(api_url, form)
      .then((response) => {
        context.commit("SET_WEBSITES", response.data.data);
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
      });
  },
};

const mutations = {
  SET_WEBSITES(state, list) {

    state.websitesList = list;
  },
  SHOW_LOADER(state, status) {
    state.loaderstate = status;
  },
  SHOW_SNACKBAR(state, payload) {
    state.showSnackBar.show = true;
    state.showSnackBar.message = payload.message;
    var color = "red";
    if (payload.success) {
      color = "primary";
    }
    state.showSnackBar.color = color;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
